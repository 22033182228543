<template>
  <div id="review">
    <div v-show="(premium || !canUpdate) && !loadingPremium">
      <vs-row class="p-0 ">
        <vs-col class="container-element pb-0 mt-3" vs-w="12">
          <p class="label-content label">
            Prêmio à {{ restituir ? "restituir" : "cobrar" }}
          </p>

          <currency-input
            :value="premium"
            class="w-full vs-inputx vs-input--input large hasValue"
            disabled
          />
        </vs-col>
      </vs-row>
      <vs-row class="pt-2" v-if="restituir">
        <vs-col
          class="pt-0"
          vs-type="flex"
          vs-justify="end"
          vs-align="end"
          vs-w="12"
        >
          <vs-button class="px-1" type="flat" @click="solicitarReview()">
            Solicitar revisão
          </vs-button>
        </vs-col>
      </vs-row>
      <div v-if="IsBankInformationNeeded">
        <template v-if="restituir && (premium || !canUpdate)">
          <vs-row>
            <vs-col vs-w="12">
              <h4 class="vx-col w-full flex items-center font-semibold p-0">
                Dados Bancários do tomador
              </h4>
            </vs-col>
            <div v-if="policyHolderBankInfo.BankAccountTypeId">
              <vs-col vs-w="12" class="container-element">
                <p class="label-content label">
                  Banco
                </p>
                <ValidationProvider
                  name="banco"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Name"
                    size="large"
                    placeholder="Selecione um banco"
                    class="w-full"
                    :value="policyHolderBankInfo.BankId"
                    :options="bancos"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    :reduce="banco => banco.Id"
                    :disabled="true"
                    @input="
                      updateEndosso({
                        PolicyHolder: {
                          ...(endosso.PolicyHolder || {}),
                          BankAccount: {
                            ...((endosso.PolicyHolder || {}).BankAccount || {}),
                            BankId: $event
                          }
                        }
                      })
                    "
                  />

                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>

              <vs-col vs-w="12" class="container-element">
                <p class="label-content label">
                  Tipo da conta
                </p>
                <ValidationProvider
                  name="tipo da conta"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Name"
                    size="large"
                    placeholder="Selecione um tipo"
                    class="w-full"
                    :value="policyHolderBankInfo.BankAccountTypeId"
                    :options="tipoContaList"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    :reduce="banco => banco.Id"
                    :disabled="true"
                    @input="
                      updateEndosso({
                        PolicyHolder: {
                          ...(endosso.PolicyHolder || {}),
                          BankAccount: {
                            ...((endosso.PolicyHolder || {}).BankAccount || {}),
                            BankAccountTypeId: $event
                          }
                        }
                      })
                    "
                  />

                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>

              <vs-col vs-lg="8" vs-sm="12">
                <ValidationProvider
                  name="agência"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <vs-input
                    label="Agência"
                    size="large"
                    :value="policyHolderBankInfo.BankBranchNumber"
                    :disabled="true"
                    @input="
                      updateEndosso({
                        PolicyHolder: {
                          ...(endosso.PolicyHolder || {}),
                          BankAccount: {
                            ...((endosso.PolicyHolder || {}).BankAccount || {}),
                            BankBranchNumber: $event
                          }
                        }
                      })
                    "
                    v-mask="['###', '####', '#####', '######', '#######']"
                    class="w-full"
                  />

                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>

              <vs-col vs-lg="4" vs-sm="12">
                <vs-input
                  label="Digito"
                  size="large"
                  :value="policyHolderBankInfo.BankBranchDigit"
                  :disabled="true"
                  @input="
                    updateEndosso({
                      PolicyHolder: {
                        ...(endosso.PolicyHolder || {}),
                        BankAccount: {
                          ...((endosso.PolicyHolder || {}).BankAccount || {}),
                          BankBranchDigit: $event
                        }
                      }
                    })
                  "
                  v-mask="['#', '##']"
                  class="w-full"
                />
              </vs-col>

              <vs-col vs-lg="8" vs-sm="12">
                <ValidationProvider
                  name="conta"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <vs-input
                    label="Conta"
                    size="large"
                    :value="policyHolderBankInfo.BankAccountNumber"
                    :disabled="true"
                    @input="
                      updateEndosso({
                        PolicyHolder: {
                          ...(endosso.PolicyHolder || {}),
                          BankAccount: {
                            ...((endosso.PolicyHolder || {}).BankAccount || {}),
                            BankAccountNumber: $event
                          }
                        }
                      })
                    "
                    v-mask="[
                      '###',
                      '####',
                      '#####',
                      '######',
                      '#######',
                      '####################'
                    ]"
                    class="w-full"
                  />

                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>

              <vs-col vs-lg="4" vs-sm="12">
                <vs-input
                  label="Digito"
                  size="large"
                  :value="policyHolderBankInfo.BankAccountDigit"
                  :disabled="true"
                  @input="
                    updateEndosso({
                      PolicyHolder: {
                        ...(endosso.PolicyHolder || {}),
                        BankAccount: {
                          ...((endosso.PolicyHolder || {}).BankAccount || {}),
                          BankAccountDigit: $event
                        }
                      }
                    })
                  "
                  v-mask="['#']"
                  class="w-full"
                />
              </vs-col>
            </div>
            <el-alert
              class="m-5"
              v-else
              title="Atenção"
              type="warning"
              description="Necessário incluir dados bancários para esse tomador!"
              :closable="false"
              show-icon
            >
            </el-alert>
            <vs-col
              class="pt-0"
              vs-type="flex"
              vs-justify="center"
              vs-align="end"
              vs-w="12"
            >
              <vs-button
                class="px-5"
                color="primary"
                @click="bankInfoModal = true"
              >
                Gerenciar dados Bancários
              </vs-button>
            </vs-col>
          </vs-row>
        </template>
      </div>
      <vs-popup
        title=""
        id="review-popup"
        :active.sync="bankInfoModal"
        class="modal-bank"
      >
        <dadosBancariosComponent
          v-if="bankInfoModal"
          @callSelectedBank="callSelectedBank"
          :mode="'endosso'"
          :personId="0"
          :personUniqueId="
            $store.state['apolice-module'].apolice.PolicyHolder.UniqueId
          "
        />
      </vs-popup>
      <vs-popup
        title="Solicitar revisão"
        id="review-popup"
        :active.sync="reviewModal"
      >
        <vs-row v-show="!endossoAutomatico">
          <vs-col vs-lg="6" vs-sm="12">
            <p class="label-content label">Valor sugerido</p>
            <currency-input
              :value="premio"
              class="w-full vs-inputx vs-input--input large hasValue"
              disabled
            />
          </vs-col>
          <vs-col vs-lg="6" vs-sm="12">
            <p class="label-content label">
              Valor solicitado
            </p>

            <ValidationProvider
              name="Valor solicitado"
              :rules="{ required: reviewModal }"
              v-slot="{ errors }"
            >
              <currency-input
                v-model="novoPremio"
                class="w-full vs-inputx vs-input--input large hasValue"
              />

              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </vs-col>

          <vs-col vs-lg="12" vs-sm="12">
            <ValidationProvider
              name="Justificativa"
              :rules="{ required: reviewModal }"
              v-slot="{ errors }"
            >
              <vs-input
                label="Justificativa"
                size="large"
                class="w-full"
                :value="endosso.JustificationInsurancePremiumReview"
                @input="
                  updateEndosso({
                    JustificationInsurancePremiumReview: $event
                  })
                "
              />

              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="mt-5 px-4">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button @click="updateReviewData()">
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import instance from "@/axios";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";
import dadosBancariosComponent from "../../../views/tomador/components/dadosBancarios.vue";
export default {
  directives: { mask },
  props: {
    loadingPremium: { type: Boolean, default: false },
    restituir: { type: Boolean, default: false },
    IsBankInformationNeeded: { type: Boolean, default: false },
    canUpdate: { type: Boolean, default: true },
    apolice: { type: Object }
  },
  components: {
    dadosBancariosComponent
  },
  data() {
    return {
      bankInfo: {},
      novoPremio: null,
      premio: null,
      reviewModal: false,
      bankInfoModal: false,
      justificativa: "",
      endossoAutomatico: false,
      premium: null,
      bancos: [],
      tipoContaList: [
        {
          Id: 3,
          Name: "Conta corrente individual"
        },
        {
          Id: 4,
          Name: "Conta poupança individual"
        },
        {
          Id: 5,
          Name: "Conta corrente conjunta"
        },
        {
          Id: 6,
          Name: "Conta poupança conjunta"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("endosso-module", ["endosso"]),
    policyHolderBankInfo() {
      // console.log("scss", this.bankInfo);
      this.updateEndosso({
        PolicyHolder: {
          BankAccount: {
            BankAccountTypeId: this.bankInfo.BankAccountTypeId,
            BankId: this.bankInfo.BankId,
            BankBranchDigit: this.bankInfo.DigitBranch,
            BankAccountNumber: this.bankInfo.Number,
            BankAccountDigit: this.bankInfo.DigitNumber,
            BankBranchNumber: this.bankInfo.Branch,
            BankPersonAccountId: this.bankInfo.Id
          }
        }
      });

      return {
        ...((this.endosso.PolicyHolder || {}).BankAccount || {
          BankAccountTypeId: this.bankInfo.BankAccountTypeId,
          BankId: this.bankInfo.BankId,
          BankBranchDigit: this.bankInfo.DigitBranch,
          BankAccountNumber: this.bankInfo.Number,
          BankAccountDigit: this.bankInfo.DigitNumber,
          BankBranchNumber: this.bankInfo.Branch,
          BankPersonAccountId: this.bankInfo.Id
        })
      };
    }
  },
  methods: {
    ...mapMutations("endosso-module", ["updateEndosso"]),
    ...mapActions("seguradora-module", ["getBankList"]),
    callSelectedBank(conta, modal) {
      this.bankInfoModal = modal;
      console.log("conta2", conta);
      if (conta) {
        this.bankInfo = conta;
      } else {
        this.bankInfo.BankAccountTypeId = "";
        this.bankInfo.BankId = "";
        this.bankInfo.BankBranchDigit = "";
        this.bankInfo.BankAccountNumber = "";
        this.bankInfo.BankAccountDigit = "";
        this.bankInfo.BankBranchNumber = "";
        this.bankInfo.BankPersonAccountId = "";
      }
    },
    getBankAccounts() {
      return instance
        .get(
          `/api/Core/GetPersonBankAccounts?personUniqueId=${this.$store.state["apolice-module"].apolice.PolicyHolder.UniqueId}&lastBankAccount=true`
        )
        .then(response => {
          if (response.data.Response[0]) {
            this.bankInfo = response.data.Response[0];
          }
        });
    },
    solicitarReview() {
      this.reviewModal = true;
      this.justificativa =
        this.endosso.JustificationInsurancePremiumReview || "";

      if (
        this.endosso.RequestInsurancePremiumReview !== undefined &&
        this.endosso.RequestInsurancePremiumReview !== null &&
        this.endosso.RequestInsurancePremiumReview !== 0
      )
        this.premio = Math.abs(this.endosso.RequestInsurancePremiumReview);
      else this.premio = 0.0;
    },

    updateReviewData() {
      if (this.endossoAutomatico)
        this.updateEndosso({
          RestoreInsurancePremium: !this.endossoAutomatico,
          RequestInsurancePremiumReview: 0.0,
          JustificationInsurancePremiumReview: ""
        });
      else
        this.updateEndosso({
          RestoreInsurancePremium: !this.endossoAutomatico,
          RequestInsurancePremiumReview: this.restituir
            ? this.novoPremio
            : -Math.abs(this.novoPremio)
        });

      this.premium = Math.abs(this.endosso.RequestInsurancePremiumReview);
      this.reviewModal = false;
      this.$forceUpdate();
    }
  },
  watch: {
    loadingPremium(value) {
      this.getBankAccounts();
      if (!value) {
        if (
          this.endosso.RequestInsurancePremiumReview > 0 ||
          this.endosso.RequestInsurancePremiumReview < 0
        )
          this.premium = Math.abs(this.endosso.RequestInsurancePremiumReview);
        else this.premium = 0.0;
      }
    },

    restituir(value) {
      if (!value)
        this.updateEndosso({
          PolicyHolder: {
            ...(this.endosso.PolicyHolder || {}),
            BankAccount: null
          }
        });
    }
  },
  async created() {
    await this.getBankList().then(result => {
      this.bancos = result;
    });
  }
};
</script>

<style lang="scss">
.modal-bank .vs-popup {
  min-width: 85vw !important;
}
#review-popup {
  .container-element {
    position: relative;

    .label-content {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 5px;
    }
    .label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }
  }
}

#review {
  #endosso-loading {
    min-width: 120px;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    .con-vs-loading {
      z-index: 1;
      background: transparent;
    }
  }

  .vs-row {
    .vs-col {
      padding: 1rem 0.2rem;
      .vs-con-input-label {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
